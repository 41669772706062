import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncAction } from './utils'
import * as customersAPIs from '../api/customer'

export interface Document {
  customers: []
  customer:{}
}

const initialState = {
  customers: [],
  customer: {}
}

const fileSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomers(state, action: PayloadAction<Partial<Document>>) {
      Object.assign(state, action.payload)
    }
  },
})

export const { setCustomers } = fileSlice.actions
export default fileSlice.reducer

export const getCustomers = createAsyncAction((payload) => {
  return async(dispatch) => {
    try {
      const response = await customersAPIs.getCustomer(payload)
      dispatch(setCustomers({customers: response.data.data}))
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const getCustomerById = createAsyncAction((id: any) => {
  return async(dispatch) => {
    try {
      const response = await customersAPIs.getCustomerById(id)
      dispatch(setCustomers({customer: response?.data?.data}))
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const createCustomer = createAsyncAction((payload) => {
  return async(dispatch) => {
    try {
      const result = await customersAPIs.createCustomer(payload)
      dispatch(setCustomers({customer: result?.data?.data}))
      if (result) {
        return result
      }
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const createPassword = createAsyncAction((payload) => {
  return async(dispatch) => {
    try {
      const result = await customersAPIs.createPassword(payload)
      dispatch(setCustomers({customer: result?.data?.data}))
      if (result) {
        return result
      }
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const deleteCustomer = createAsyncAction((payload) => {
  return async() => {
    try {
      await customersAPIs.deleteCustomer(payload)
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const updateCustomer = createAsyncAction((payload, id) => {
  return async dispatch => {
    try {
      const response = await customersAPIs.updateCustomer(payload, id)
      dispatch(setCustomers({customer: response?.data?.data}))
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})
