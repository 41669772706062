import { createSlice } from '@reduxjs/toolkit'
import { createAsyncAction } from './utils'
import * as typeAPIs from '../api/type'

const initialState = {
  list: []
}

const userSlice = createSlice({
  name: 'type',
  initialState,
  reducers: {
    setTypeData(state, action) {
      Object.assign(state, action.payload)
    },
  },
})

export const { setTypeData } = userSlice.actions
export default userSlice.reducer

export const getTypes = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      const { data: { data } } = await typeAPIs.getTypes(payload)
      dispatch(setTypeData({list: data}))
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const resetType = createAsyncAction(() => {
  return async dispatch => {
    dispatch(setTypeData({list: []}))
  }
})

