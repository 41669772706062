import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit'
import { Role } from '@/interface/user/login'
import { Locale, UserState } from '@/interface/user/user'
import { createAsyncAction } from './utils'
import { getGlobalState } from '../utils/getGloabal'
import * as authAPI from '../api/auth'
import localStorageService from '../utils/localStorageService'

const initialState: UserState = {
  ...getGlobalState(),
  locale: (localStorage.getItem('locale')! || 'en_US') as Locale,
  logged: !!localStorage.getItem('Token'),
  role: 'user1' as Role,
  profileInfo: {},
  menuList: [],
  username: ''
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserItem(state, action: PayloadAction<Partial<UserState>>) {
      Object.assign(state, action.payload)
    }
  },
})

export const { setUserItem } = userSlice.actions
export default userSlice.reducer

export const login = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      const result = await authAPI.login(payload)
      if (result) {
        localStorageService.setItem('Token', result.data.token)
        const user = await authAPI.me()
        dispatch(
          setUserItem({
            logged: true,
            profileInfo: user?.data?.admin
          }),
        )
        return true
      }
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const updateProfile = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      const response = await authAPI.updateProfile(payload)
      dispatch(setUserItem({
        profileInfo: response?.data?.user
      }))
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const me = createAsyncAction(() => {
  return async dispatch => {
    try {
      const user = await authAPI.me()
      dispatch(setUserItem({
        profileInfo: user?.data?.admin
      }))
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const signUp = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      const result = await authAPI.signUp(payload)
      if (result) {
        localStorage.setItem('Token', result.data.access_token)
        dispatch(
          setUserItem({
            logged: true,
          }),
        )
      }
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const accountVerification = createAsyncAction((payload) => {
  return async() => {
    try {
      await authAPI.accountVerification(payload)
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const accountActivation = createAsyncAction(() => {
  return async() => {
    try {
      await authAPI.accountActivation()
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const logout = () => {
  return async(dispatch: Dispatch) => {
    try {
      await authAPI.logout()
      localStorage.clear()
      dispatch(
        setUserItem({
          logged: false,
        })
      )
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}
