
import { Button, Card, Checkbox, Form, Grid, Input, Space, Typography } from 'antd'
import React from 'react'

import styles from './index.module.scss'
import Logo from '../../assets/images/logo3.svg'

const { Title, Text, Link } = Typography
const { useBreakpoint } = Grid

function index() {
  const breakpoint = useBreakpoint()

  return (
    <div className={styles['layout']}>
      <img src={Logo} width={187} height={48} />
      <Card className={styles['card-control']} bordered={false} style={{ padding: breakpoint?.xs ? '20px 0px' : '32px 32px' }}>
        <Title level={2} style={{ marginTop: 0, marginBottom: '10px' }}>
          Sign up for an account
        </Title>
        <Text>Enter the field below to get started</Text>
        <Form style={{ marginTop: '32px' }} layout="vertical">
          <Form.Item
            name="username"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Uname',
              },
            ]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'password',
              },
            ]}
          >
            <Input placeholder="Enter Password" />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm Password"
            rules={[
              {
                required: true,
                message: 'password',
              },
            ]}
          >
            <Input placeholder="Confirm Password" />
          </Form.Item>
          <Checkbox className="checkbox">
            I have read and accept the <Link>Terms & Conditions</Link> and <Link>Privacy Policy</Link>
          </Checkbox>
          <Button style={{ marginTop: '20px' }} type="primary" block>
            Sign up
          </Button>
          <Space style={{ justifyContent: 'center', width: '100%', marginTop: '20px' }}>
            <Text>Already have an account?</Text>
            <Link style={{ color: 'black' }}>Sign in</Link>
          </Space>
        </Form>
      </Card>
    </div>
  )
}

export default index
