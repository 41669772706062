import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

// Configure the Redux store
const store = configureStore({
  reducer: rootReducer, // Set the root reducer
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools only in non-production environment
})

export type AppState = ReturnType<typeof rootReducer> // Define the type for the application state
export type AppDispatch = typeof store.dispatch // Define the type for the application dispatch function
export type AppStore = typeof store // Define the type for the Redux store

export default store
