import { createSlice } from '@reduxjs/toolkit'
import { createAsyncAction } from './utils'
import * as typeAPIs from '../api/type'
import localStorageService from '../utils/localStorageService'

const initialState = {
  sizeList: []
}

const userSlice = createSlice({
  name: 'size',
  initialState,
  reducers: {
    setSizeData(state, action) {
      Object.assign(state, action.payload)
    },
  },
})

export const { setSizeData } = userSlice.actions
export default userSlice.reducer

export const getSizes = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      const { data: { data } } = await typeAPIs.getSizes(payload)
      dispatch(setSizeData({sizeList: data}))
      localStorageService.setItemWithExpiration('sizes', data, 24)
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const resetSize = createAsyncAction(() => {
  return async dispatch => {
    dispatch(setSizeData({sizeList: []}))
  }
})

