import { FC, ReactElement } from 'react'

// Define the props for the Route component
interface RouteProps {
  element: any
}

// Define the props for the WrapperRoute component, extending RouteProps
export interface WrapperRouteProps extends RouteProps {
  titleId: string
  auth?: boolean
}

// Define the WrapperRoute component as a functional component
const WrapperRouteComponent: FC<WrapperRouteProps> = ({ titleId, auth, ...props }) => {
  // If titleId is provided, set the document title
  if (titleId) {
    document.title = 'Vikas Tiles'
  }

  // Return the element as a ReactElement
  return props.element as ReactElement
}

export default WrapperRouteComponent
