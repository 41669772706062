import { FC, useState } from 'react'
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Dropdown, Layout, theme as antTheme, Grid, Modal, Button, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { logout } from '../../redux/user.store'
import Avator from '../../assets/images/avator.jpeg'
import styles from './index.module.scss'
import MobileLogo from '../../assets/images/mobile_logo.svg'
import i18n from '../../i18n/index'
import CustomCloseIcon from '../../utils/customIcons/CustomCloseIcon'
import CustomChevron from '../../utils/customIcons/CustomChevron'

const { Header } = Layout
const { Title } = Typography

interface HeaderProps {
  collapsed: boolean
  toggle: () => void
}

type Action = 'userInfo' | 'userSetting' | 'logout'

const { useBreakpoint} = Grid
const HeaderComponent: FC<HeaderProps> = ({ collapsed, toggle }) => {
  const { logged } = useSelector(state => state.user)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const token = antTheme.useToken()
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const onActionClick = async(action: Action) => {
    switch (action) {
      case 'userInfo':
        return
      case 'userSetting':
        return
      case 'logout':
        try {
          const res = Boolean(await dispatch(logout()))
          res && navigate('/login')
          setLoading(false)
          return
        } catch (error) {
          console.log('error: ', error)
          navigate('/login')
          setLoading(false)
        }
    }
  }

  const toLogin = () => {
    navigate('/login')
  }

  const confirmModal = () => {
    setLoading(true)
    onActionClick('logout')
  }
  const cancel = () => {
    setOpen(false)
  }

  // const selectLocale = ({ key }: { key: any }) => {
  //   dispatch(setUserItem({ locale: key }))
  //   localStorage.setItem('locale', key)
  // }

  // const onChangeTheme = () => {
  //   const newTheme = theme === 'dark' ? 'light' : 'dark'

  //   localStorage.setItem('theme', newTheme)
  //   dispatch(
  //     setGlobalState({
  //       theme: newTheme,
  //     }),
  //   )
  // }

  return (
    <Header className={`${styles['layout-page-header']} ${styles['bg-2']}`} style={{ backgroundColor: token.token.colorBgContainer }}>
      {
        !breakpoint.lg &&
        <div className={styles['logo']} >
          <img src={MobileLogo} className={styles['image-logo']} alt="" />
        </div>
      }
      <div className={styles['layout-page-header-main']}>
        {
          !breakpoint?.lg &&
          <div onClick={toggle}>
            <span id="sidebar-trigger">{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</span>
          </div>
        }
        <div></div>
        <div className={styles['actions']}>
          {logged ? (
            <Dropdown
              menu={{
                items: [
                  // {
                  //   key: '1',
                  //   icon: <UserOutlined />,
                  //   label: <span onClick={() => navigate('/dashboard')}>account</span>,
                  // },
                  {
                    key: '2',
                    icon: <LogoutOutlined />,
                    label: (
                      <div onClick={(e) => { e.stopPropagation(); setOpen(e => !e) }}>
                        { t("header.logOut") }
                      </div>
                    ),
                  },
                ],
              }}
              className={styles['dropdown']}
              trigger={['click']}
            >
              <span className={styles['user-action']}>
                <img src={Avator} className={styles['user-avator']} alt="avator" />
                <CustomChevron />
              </span>
            </Dropdown>
          ) : (
            <span style={{ cursor: 'pointer' }} onClick={toLogin}>
              Login
            </span>
          )}
        </div>
      </div>
      <Modal
        title={<></>}
        centered
        open={open}
        width={450}
        confirmLoading={loading}
        footer={<div></div>}
        className={styles['modal']}
        onCancel={cancel}
        closeIcon={<CustomCloseIcon />}
      >
        <Title level={3} style={{ textAlign: 'center'}}>{i18n.t('header.logOut')}</Title>
        <p>{i18n.t('header.logoutConfirm')}</p>
        <div className={styles['button-control']}>
          <Button onClick={cancel} block type='primary' size='large'>No</Button>
          <Button onClick={async() => { confirmModal() }} block type='primary' size='large'>Yes</Button>
        </div>
      </Modal>
    </Header>
  )
}

export default HeaderComponent
