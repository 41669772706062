import { createSlice } from '@reduxjs/toolkit'
import { createAsyncAction } from './utils'
import * as typeAPIs from '../api/type'
import localStorageService from '../utils/localStorageService'

const initialState = {
  areaList: [],
  x: []
}

const userSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    setAreaData(state, action) {
      Object.assign(state, action.payload)
    },
  },
})

export const { setAreaData } = userSlice.actions
export default userSlice.reducer

export const getAreas = createAsyncAction(() => {
  return async dispatch => {
    try {
      const { data: { data } } = await typeAPIs.getAreas()
      dispatch(setAreaData({areaList: data}))
      localStorageService.setItemWithExpiration('areas', data, 24)
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

