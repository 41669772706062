import React from 'react'

function CustomChevron() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3876 8.34318C17.7781 7.95266 18.4113 7.95266 18.8018 8.34318C19.1924 8.73371 19.1924 9.36687 18.8018 9.7574L12.8018 15.7574C12.4233 16.136 11.8137 16.1492 11.419 15.7874L5.419 10.2874C5.01188 9.91425 4.98438 9.28168 5.35757 8.87457C5.73076 8.46745 6.36333 8.43994 6.77045 8.81314L12.0647 13.6662L17.3876 8.34318Z" fill="black"/>
    </svg>
  )
}

export default CustomChevron
