import type { FC } from 'react'
import type { RouteObject } from 'react-router'
import { lazy } from 'react'
import { useRoutes, Navigate } from 'react-router-dom'
import LayoutPage from '../pages/layout'
import LoginPage from '../pages/login'
import WrapperRouteComponent from './config'
import SignupPage from '../pages/signup'
import { RootStateOrAny, useSelector } from 'react-redux'
import Loadable from '../utils/loader/Loadable'

const NotFound = Loadable(lazy(() => import('../pages/404')))
const Properties = Loadable(lazy(() => import('../pages/properties/index')))
const Chemicals = Loadable(lazy(() => import('../pages/chemicals/index')))
const Sanitary = Loadable(lazy(() => import('../pages/sanitary/index')))
const Mirror = Loadable(lazy(() => import('../pages/mirror/index')))
const Stone = Loadable(lazy(() => import('../pages/properties/tile/Stone')))
const BuildToRent = Loadable(lazy(() => import('../pages/properties/create-property/buy-to-rent-property/index')))
const ChemicalsCreate = Loadable(lazy(() => import('../pages/chemicals/ChemicalsCreate')))

const SanitaryCreate = Loadable(lazy(() => import('../pages/sanitary/ChemicalsCreate')))
const MirrorCreate = Loadable(lazy(() => import('../pages/mirror/MirrorCreate')))

const Customer = Loadable(lazy(() => import('../pages/customer/list-customer/index')))
const AddCustomer = Loadable(lazy(() => import('../pages/customer/add-customer/index')))
const PaymentInvoiceForm = Loadable(lazy(() => import('../pages/Payment/PaymentInvoiceForm')))
const PaymentInvoice = Loadable(lazy(() => import('../pages/Payment/index')))
const PrintInvoice = Loadable(lazy(() => import('../pages/Payment/PrintInvoice')))

const RenderRouter: FC = () => {
  const { logged } = useSelector((state: RootStateOrAny) => state.user)
  const routeList: RouteObject[] = [
    {
      path: '/login',
      element: <WrapperRouteComponent element={<LoginPage />} titleId="title.login" />,
    },
    {
      path: '/signup',
      element: <WrapperRouteComponent element={<SignupPage />} titleId="title.signup" />,
    },
    {
      path: '/',
      element: <WrapperRouteComponent element={ logged ? <LayoutPage /> : <LoginPage /> } titleId="" />,
      children: [
        {
          path: '',
          element: <Navigate to="tile" />,
        },
        {
          path: 'tile',
          element: <WrapperRouteComponent element={<Properties />} titleId="Admin | Tiles" />,
        },
        {
          path: 'customer',
          element: <WrapperRouteComponent element={<Customer />} titleId="title.documentation" />,
        },
        {
          path: 'tile/create/id?/category?',
          element: <WrapperRouteComponent element={<BuildToRent />} titleId="title.documentation" />,
        },
        {
          path: 'stone/create/',
          element: <WrapperRouteComponent element={<Stone />} titleId="title.documentation" />,
        },
        {
          path: 'chemicals',
          element: <WrapperRouteComponent element={<Chemicals />} titleId="Admin | Tiles" />,
        },
        {
          path: 'chemicals/create/',
          element: <WrapperRouteComponent element={<ChemicalsCreate />} titleId="title.documentation" />,
        },

        {
          path: 'sanitary',
          element: <WrapperRouteComponent element={<Sanitary />} titleId="Admin | Tiles" />,
        },
        {
          path: 'sanitary/create/',
          element: <WrapperRouteComponent element={<SanitaryCreate />} titleId="title.documentation" />,
        },
        {
          path: 'mirror',
          element: <WrapperRouteComponent element={<Mirror />} titleId="Admin | Tiles" />,
        },
        {
          path: 'mirror/create/',
          element: <WrapperRouteComponent element={<MirrorCreate />} titleId="title.documentation" />,
        },

        {
          path: 'create-customer/id?',
          element: <WrapperRouteComponent element={<AddCustomer />} titleId="title.documentation" />,
        },
        {
          path: 'payment/create',
          element: <WrapperRouteComponent element={<PaymentInvoiceForm />} titleId="title.documentation" />,
        },
        {
          path: 'payment',
          element: <WrapperRouteComponent element={<PaymentInvoice />} titleId="title.documentation" />,
        },
        {
          path: 'invoice-print/id?',
          element: <WrapperRouteComponent element={<PrintInvoice />} titleId="title.documentation" />,
        },
        {
          path: '*',
          element: <WrapperRouteComponent element={<NotFound />} titleId="title.notFount" />,
        },
      ],
    },
  ]

  const element = useRoutes(routeList)
  return element
}

export default RenderRouter
