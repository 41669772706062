import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import * as au from './au/au.json'
import * as en from './en/en.json'

// Define the language resources for different locales
const resources = {
  en, // English language resource
  au, // Australian language resource
}

// Initialize i18next with React
i18n
  .use(initReactI18next) // Initialize i18next with React integration
  .use(LanguageDetector) // Enable language detection
  .init({
    resources, // Language resources for different locales
    interpolation: {
      escapeValue: false, // Disable escaping of HTML entities in translations
    },
  })

export default i18n
