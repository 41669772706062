import request from '../utils/request'

function login(data: any) {
  return request({
    url: '/auth/login',
    method: 'post',
    data,
  })
}

function signUp(data: any) {
  return request({
    url: '/sign_up',
    method: 'post',
    data,
  })
}

function logout() {
  return request({
    url: '/auth/logout',
    method: 'get',
  })
}

function accountVerification(token: string) {
  return request({
    url: `/verify/${token}`,
    method: 'post',
  })
}

function accountActivation() {
  return request({
    url: `/send_verification_email`,
    method: 'post',
  })
}

function me() {
  return request({
    url: `/auth/me`,
    method: 'get',
  })
}

function updateProfile(data: Object) {
  return request({
    url: `/users/profile`,
    method: 'put',
    data,
  })
}

export { accountActivation, accountVerification, login, logout, me, signUp, updateProfile }
