import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncAction } from './utils'
import * as invoiceAPIs from '../api/invoice'

export interface Invoice {
  invoices: []
  invoice:{}
}

const initialState = {
  invoices: [],
  invoice: {}
}

const fileSlice = createSlice({
  name: 'Invoices',
  initialState,
  reducers: {
    setInvoiceData(state, action: PayloadAction<Partial<Invoice>>) {
      Object.assign(state, action.payload)
    },
    removePayment(state, action) {
      state.invoices = state.invoices.filter((el: any) => el._id !== action.payload)
    },
  },
})

export const { setInvoiceData, removePayment } = fileSlice.actions
export default fileSlice.reducer

export const getInvoices = createAsyncAction((payload) => {
  return async(dispatch) => {
    try {
      const response = await invoiceAPIs.getInvoice(payload)
      dispatch(setInvoiceData({invoices: response?.data?.data}))
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const getInvoiceById = createAsyncAction((id: any) => {
  return async(dispatch) => {
    try {
      const response = await invoiceAPIs.getInvoiceById(id)
      dispatch(setInvoiceData({invoice: response?.data?.data}))
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const createInvoice = createAsyncAction((payload) => {
  return async(dispatch) => {
    try {
      const result = await invoiceAPIs.createInvoice(payload)
      dispatch(setInvoiceData({invoice: result?.data?.data}))
      if (result) {
        return result
      }
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const updateInvoice = createAsyncAction((payload, id) => {
  return async dispatch => {
    try {
      const response = await invoiceAPIs.updateInvoice(payload, id)
      dispatch(setInvoiceData({invoice: response?.data?.data}))
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const deletePayment = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      await invoiceAPIs.deletePayment(payload)
      dispatch(removePayment(payload))
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const resetData = createAsyncAction((payload, id) => {
  return async dispatch => {
    dispatch(setInvoiceData({
      invoice: {},
      invoices: []
    }))
  }
})
