import moment from "moment"
import imageCompression from 'browser-image-compression'

// Regular expression for matching a 10-digit mobile number
export const mobileRegex = /^\d{10}$/

// Regular expression for matching a 6-digit pin code
export const pinCodeRegex = /^\d{6}$/

// Regular expression for matching a decimal number, allowing optional negative sign and decimal point
export const decimalRegex = /^(?!0+(\.0+)?$)(?:[1-9]\d*|0)(?:\.\d+)?$/

// Regular expression for matching a percentage, ranging from 0 to 100
export const percentRegex = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/
export const blankSpacesRegex = /^(?! )(?!.*\s{2})\S.*$/
export const specialCharsRegex = /^[A-Za-z0-9-,.&/\s]+$/

// Function to capitalize the first letter of a string
export const capitalizeFirstLetter = (string: string) => {
  if (string) {
    // Return the string with the first letter capitalized
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

export const monthList = [...Array(18).keys()].map((number) => number + 1)

export const fieldTypes = [
  {
    name: 'Number',
    value: 'number',
  }
]
export const getStatus = (item: any) => {
  const receivedAmountSum = item.paymentHistory.reduce(
    (sum: any, payment: any) => sum + payment.receivedAmount + parseInt(item?.paymentHistory?.[0]?.flatDiscount),
    0
  );

  if (receivedAmountSum === item.subTotal) {
    return "Done";
  } else {
    return "Pending";
  }
}

export function formatDateWithMoment(inputDateStr: any) {
  const inputDate = moment(inputDateStr)
  return inputDate.format("MMMM DD, YYYY")
}

export function formatDateWithMomentShort(inputDateStr: any) {
  if (inputDateStr) {
    const inputDate = moment(inputDateStr)
    return inputDate.format("ll")
  } else {
    return null
  }
}

export const onlyNumeric = (e: any) => {
  const key = e.key
  const excludedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"]
  if (
    !(
      (key >= "0" && key <= "9") || // Numbers 0 to 9
      excludedKeys.includes(key) // Backspace, left arrow, right arrow, delete keys
    )
  ) {
    e.preventDefault()
  }
}

export const generateInvoiceNumber = (data: any) => {
  if (Object.keys(data).length) {
    const month = data?.createdAt?.toString()?.split('-')[1]
    const monthOnePlush = parseInt(month) + 1
    return 'VT' + data?.customer?.mobile.toString().slice(0, 2) + data?.customer?.name?.toUpperCase().slice(0, 2) + data?.shop.toUpperCase() + monthOnePlush
  }
}

export const getPaymentMethodsAsString = (array: any) => {
  if (array?.length) {
    const paymentMethods = array?.map((item: any) => item.paymentMethod)
    return paymentMethods.join(', ');
  } else {
    return 'NA'
  }
}

export const addressLko01 = {
  GISTIN: '09BXOPP3531R1ZJ',
  address: "Kalli Pashchim raebareli road",
  dis: 'Lucknow',
  pincode: '226301',
  state: 'uttaer Pradesh',
  mobile1: '9794397420',
  mobile2: '9621292643',
}

export const addresskl = {
  GISTIN: '09DBYPA9594P2Z7',
  address: "Atarihan khera muktemau purwa",
  dis: 'Unnao',
  pincode: '209821',
  state: 'uttaer Pradesh',
  mobile1: '9794397420',
  mobile2: '6394287179',
}

export const paymentMethods = [{
  id: 'Cash',
  name: 'Cash',
},
{
  id: 'UPI',
  name: 'UPI',
},
{
  id: 'Check',
  name: 'Check',
},
{
  id: 'Card',
  name: 'Card',
},
]

export const numericGate = (event: any) => {
  const key = event.key
  const keyCode = event.keyCode || event.which
  const excludedKeys = [8, 37, 39, 46, 9]
  const inputValue = event.target.value
  const hasDecimalPoint = inputValue.includes('.')
  const decimalPosition = inputValue.indexOf('.')
  // Allow numeric keys, backspace, left arrow, right arrow, delete, and tab keys
  if (
    (key >= '0' && key <= '9') ||
    excludedKeys.includes(keyCode) ||
    (keyCode === 190 && (!hasDecimalPoint && inputValue.length !== 0)) // Allow the period (.) key only if there's no existing decimal point
  ) {
    // Allow only two digits after the decimal point
    if (hasDecimalPoint && decimalPosition + 2 < inputValue.length && !excludedKeys.includes(keyCode)) {
      event.preventDefault()
    }
  } else {
    event.preventDefault()
  }
}

/**
 * Creates a debounced version of the provided function, which delays its execution
 * until after a specified delay has passed. If the debounced function is called
 * again within the delay period, the previous timeout is cleared and a new timeout
 * is set, effectively restarting the delay period.
 *
 * @param func The function to be debounced.
 * @param delay The delay period in milliseconds.
 * @returns A debounced version of the provided function.
 */
export const debounce = (func: any, delay: number) => {
  let timeoutId: any

  // The returned debounced function that will be invoked when called.
  return (...args: any) => {
    // Clear the previous timeout to restart the delay period.
    clearTimeout(timeoutId)

    // Set a new timeout, delaying the execution of the function.
    timeoutId = setTimeout(() => {
      // Invoke the original function with the provided arguments after the delay.
      func(...args)
    }, delay)
  }
}

export const addCommaAfterThreeDigits = (number: number) => {
  return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const convertArrayToLowerCase = (array: any) => {
  if (!Array.isArray(array)) {
    throw new Error('Input should be an array')
  }

  return array.map(item => {
    if (typeof item !== 'string') {
      throw new Error('Array should contain only strings')
    }
    return item.toLowerCase()
  })
}

export const addCommasToDecimal = (decimalValue: any) => {
  const parts = decimalValue.toString().split('.')
  const wholePart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const formattedDecimalValue = parts.length > 1 ? `${wholePart}.${parts[1]}` : wholePart
  return formattedDecimalValue
}

export const truncateString = (inputString: string, maxLength: number) => {
  if (inputString.length <= maxLength) {
    return inputString
  } else {
    return inputString.substring(0, maxLength - 3) + "..."
  }
}

export const removeBlankKeys = (obj: any) => {
  return Object.keys(obj)
    .filter(key => obj[key] !== '' && obj[key] !== null && obj[key] !== undefined)
    .reduce((result: any, key) => {
      result[key] = obj[key]
      return result
    }, {})
}

export const getUnit = (type: string) => {
  switch (type) {
    case 'stone':
      return 'per/sqft'
    case 'tile':
      return 'per/box'
    case 'chemical':
      return 'per/bag'
    default:
      return 'per/box'
  }
}

export const getUnitName = (type: string) => {
  switch (type) {
    case 'stone':
      return 'Sqft'
    case 'tile':
      return 'Box'
    case 'chemical':
      return 'Bag'
    case 'mirror':
      return 'Unite'
    default:
      return 'Unite'
  }
}
export async function handleImageUpload(imageFile: any) {
  // Define compression options
  const options = {
    maxSizeMB: 0.15, // Maximum file size after compression is 1MB
    maxWidthOrHeight: 1200, // Maximum width or height of the image is 1920 pixels
    useWebWorker: true, // Use a web worker for the compression process
  }
  try {
    // Compress the input image file using the specified options
    const compressedFile = await imageCompression(imageFile, options)
    // Create a new File object from the compressed file
    const file = new File([compressedFile], compressedFile.name, {
      type: compressedFile.type, // Preserve the file type
      lastModified: compressedFile.lastModified // Preserve the last modified date
    })
    // Return the newly created File object
    return file
  } catch (error) {
    // Log any errors that occur during the compression process
    console.log(error)
  }
}
