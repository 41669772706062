import request from '../utils/request'

function getInvoice(params: any) {
  return request({
    url: '/invoices',
    method: 'get',
    params
  })
}

function getInvoiceById(id: any) {
  return request({
    url: `/invoices/${id}`,
    method: 'get'
  })
}

function createInvoice(data: any) {
  return request({
    url: `/invoices`,
    method: 'post',
    data
  })
}

function updateInvoice(data: any, id: number) {
  return request({
    url: `/invoices/${id}`,
    method: 'put',
    data,
  })
}

function deletePayment(id: any) {
  return request({
    url: `/invoices/${id}`,
    method: 'delete'
  })
}

export { getInvoice, getInvoiceById, createInvoice, updateInvoice, deletePayment}
