import { combineReducers } from '@reduxjs/toolkit'
import globalReducer from './global.store'
import userReducer from './user.store'
import propertyReducer from './title.store'
import typeReducer from './type.store'
import sizeReducer from './size.store'
import areaReducer from './area.store'
import invoiceReducer from './invoice.store'
import customer from './customer.store'

const rootReducer = combineReducers({
  user: userReducer,
  global: globalReducer,
  property: propertyReducer,
  type: typeReducer,
  size: sizeReducer,
  area: areaReducer,
  invoice: invoiceReducer,
  customer: customer
})

export default rootReducer
