import { createSlice } from '@reduxjs/toolkit'
import { createAsyncAction } from './utils'
import * as tileAPIs from '../api/tile'

const initialState = {
  occupancies: [],
  properties: [],
  property: {},
  count: 0,
  searchQuery: {
    page: 1,
    limit: 10
  },
  loading: false,
  errors: {
    pinCode: true,
  }
}

const userSlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setPropertyData(state: any, action) {
      Object.assign(state, action.payload)
    },
    addProperties: (state, action) => {
      state.properties.push(...action.payload)
    },
    updateTileData(state, action) {
      const updatedTile = state.properties.map((item: any) => {
        if (item._id === action.payload.id) {
          return { ...item, isPublished: action.payload.status }
        }
        return item
      })
      state.properties = updatedTile
    },
  },
})

export const { setPropertyData, addProperties, updateTileData } = userSlice.actions
export default userSlice.reducer

export const getProperties = createAsyncAction((payload) => {
  return async(dispatch) => {
    try {
      const response = await tileAPIs.getProperties(payload)
      const properties = response?.data?.data || []
      const count = response?.data?.total || {}
      dispatch(setPropertyData({count}))
      dispatch(addProperties(properties))
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const handleErrors = createAsyncAction((payload) => {
  payload = Object.entries(payload)[0]
  const key = payload[0]
  const val = payload[1]
  const errorObj = {[key]: val}
  return async dispatch => {
    dispatch(setPropertyData({errors: errorObj}))
    return true
  }
})

export const imageUpload = createAsyncAction((payload) => {
  return async() => {
    try {
      const result = await tileAPIs.imageUpload(payload)
      if (result) {
        return result
      }
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const imageDelete = createAsyncAction((payload) => {
  return async() => {
    try {
      const result = await tileAPIs.imageDelete(payload)
      if (result) {
        return result
      }
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const createProperty = createAsyncAction((payload) => {
  console.log("payload", payload)
  return async() => {
    try {
      const result = await tileAPIs.createProperty(payload)
      if (result) {
        return result
      }
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const getProperty = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      const response = await tileAPIs.getProperty(payload)
      dispatch(setPropertyData({
        property: response?.data
      }))
      return response?.data
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const resetProperty = createAsyncAction(() => {
  return async dispatch => {
    dispatch(setPropertyData({
      property: {},
      properties: []
    }))
  }
})

export const updateProperty = createAsyncAction((payload, id) => {
  return async dispatch => {
    try {
      await tileAPIs.updateProperty(payload, id)
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const setLoader = createAsyncAction((payload) => {
  return async dispatch => {
    dispatch(setPropertyData({
      loading: payload
    }))
  }
})

export const setSearchQuery = createAsyncAction((payload) => {
  return async dispatch => {
    dispatch(setPropertyData({
      searchQuery: payload
    }))
  }
})

export const publishProperty = createAsyncAction((payload, id) => {
  return async dispatch => {
    try {
      await tileAPIs.publishProperty(payload, id)
      dispatch(updateTileData({status: payload.status, id}))
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const deleteProperty = createAsyncAction((payload) => {
  return async() => {
    try {
      await tileAPIs.deleteProperty(payload)
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

