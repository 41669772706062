import type { LoginParams } from '@/interface/user/login'
import { FC, useEffect, useState } from 'react'
import { Button, Card, Form, Grid, Input, notification, Typography } from 'antd'
import styles from './index.module.scss'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/logo3.svg'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n/index'
import { login } from '../../redux/user.store'

const initialValues = {}

const { Title, Text } = Typography
const { useBreakpoint } = Grid

const LoginForm: FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()
  const location = useLocation()
  /**
   * Handler function called when the form is submitted.
   * It dispatches a login action with the form data, waits for the response,
   * and if successful, navigates to the appropriate location.
   *
   * @param {LoginParams} form - The form data containing the email and password.
   */
  const onFinished = async(form: LoginParams) => {
    try {
      setLoading(true)
      const res: any = await dispatch(login(form))
      if (res) {
        const from = { pathname: '/' }
        setLoading(true)
        navigate(from)
      }
    } catch (error: any) {
      setLoading(false)
      notification.error({message: 'Error', description: error?.error})
    }
  }

  useEffect(() => {
    if (location.pathname !== '/login') {
      navigate('/login')
    }
  }, [location])

  return (
    <div className={styles['layout']}>
      <img src={Logo} width={187} height={48} />
      <Card className={styles['card-control']} bordered={false} style={{ padding: breakpoint?.xs ? '20px 0px' : '32px 32px' }}>
        <Title level={2} style={{ marginTop: 0, marginBottom: '10px' }}>
          { t("login.heading") }
        </Title>
        <Text>{ t("login.headingDesc") }</Text>
        <Form<LoginParams> className={'login-form'} hideRequiredMark={true} style={{ marginTop: '32px' }} onFinish={onFinished} layout="vertical" initialValues={initialValues} >
          <Form.Item className="login-input" name="email" label="Email" rules={[{ required: true, type: 'email', message: `${i18n.t('validations.validEmail')}`}]}>
            <Input placeholder="Enter Email" />
          </Form.Item>
          <Form.Item className='login-input' name="password" label="Password" rules={[{ required: true, message: `${i18n.t('validations.password')}`}]}>
            <Input placeholder="Enter Password" type="password" />
          </Form.Item>
          {/* <Space style={{ width: '100%', justifyContent: 'space-between' }} align="center">
            <Checkbox>Remember Me</Checkbox>
            <Link onClick={ () => { navigate('/') } } style={{ color: 'black' }}>
              Forgot Password?
            </Link>
          </Space> */}
          <Button loading={loading} htmlType="submit" className={styles['login-button']} type="primary" block> { t("login.login") } </Button>
          {/* <Space style={{ justifyContent: 'center', width: '100%', marginTop: '20px' }}>
            <Text>{ t("login.don'tAccount") }</Text>
            <Link style={{ color: 'black' }} onClick={ () => { navigate('/signup') } } >{ t("login.signIn") }</Link>
          </Space> */}
        </Form>
      </Card>
    </div>
  )
}

export default LoginForm
