import type { MenuChild, MenuList } from '@/interface/layout/menu.interface'
import type { FC } from 'react'
import styles from './index.module.scss'

import { Drawer, Grid, Layout, theme as antTheme } from 'antd'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

import { setUserItem } from '../../redux/user.store'
import { getFirstPathCode } from '../../utils/getFirstPathCode'
import { getGlobalState } from '../../utils/getGloabal'
import HeaderComponent from './header'
import MenuComponent from './menu'
import { useLocation } from 'react-router-dom'
import LogoImage from '../../assets/images/logo3.svg'

const { Sider, Content } = Layout
const WIDTH = 992

const { useBreakpoint} = Grid
const LayoutPage: FC = () => {
  const location = useLocation()
  const breakpoint = useBreakpoint()
  const [openKey, setOpenkey] = useState<string>()
  const [selectedKey, setSelectedKey] = useState<string>(location.pathname)
  const [menuList, setMenuList] = useState<MenuList>([])
  const { collapsed } = useSelector(state => state.user)
  const token = antTheme.useToken()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const code = getFirstPathCode(location.pathname)

    setOpenkey(code)
    setSelectedKey(location.pathname)
  }, [location.pathname])

  const toggle = () => {
    dispatch(
      setUserItem({
        collapsed: !collapsed,
      }),
    )
  }

  const initMenuListAll = (menu: MenuList) => {
    const MenuListAll: MenuChild[] = []

    menu.forEach(m => {
      if (!m?.children?.length) {
        MenuListAll.push(m)
      } else {
        m?.children.forEach(mu => {
          MenuListAll.push(mu)
        })
      }
    })

    return MenuListAll
  }

  const fetchMenuList = useCallback(async() => {
    const result = [
      {
        code: 'tile',
        label: 'Tile Management',
        icon: 'tile',
        path: '/tile',
      },

      {
        code: 'chemicals',
        label: 'Chemicals & Spacer',
        icon: 'chemicals',
        path: '/chemicals',
      },
      {
        code: 'sanitary',
        label: 'Sanitary Items',
        icon: 'sanitary',
        path: '/sanitary',
      },
      {
        code: 'mirror',
        label: 'Mirrors',
        icon: 'mirror',
        path: '/mirror',
      },
      {
        code: 'customer',
        label: 'Customer',
        icon: 'properties',
        path: '/customer',
      },
      {
        code: 'payment',
        label: 'Payment',
        icon: 'payment',
        path: '/payment',
      }
    ]

    setMenuList(result)
    dispatch(
      setUserItem({
        menuList: initMenuListAll(result),
      }),
    )
  }, [dispatch])

  useEffect(() => {
    fetchMenuList()
  }, [fetchMenuList])

  useEffect(() => {
    window.onresize = () => {
      const { device } = getGlobalState()
      const rect = document.body.getBoundingClientRect()
      const needCollapse = rect.width < WIDTH

      dispatch(
        setUserItem({
          device,
          collapsed: needCollapse,
        }),
      )
    }
  }, [dispatch])

  return (
    <Layout hasSider className={styles['layout-page']}>
      {
        !breakpoint?.lg
          ? <Drawer className='custom-menu' width={290} placement="left" bodyStyle={{ padding: 0, height: '100%', backgroundColor: '#202872' }} closable={false} onClose={toggle} open={!collapsed}>
            <div className={styles['logo-controller']}>
              <img src={LogoImage} />
            </div>
            <MenuComponent
              menuList={menuList}
              openKey={openKey}
              onChangeOpenKey={k => setOpenkey(k)}
              selectedKey={selectedKey}
              onChangeSelectedKey={k => setSelectedKey(k)}
            />
          </Drawer>
          : <div className={styles['sticky-position']}>
            <Sider width={255} className={styles['layout-page-sider']} trigger={null} collapsible collapsedWidth={0} style={{ backgroundColor: token.token.colorBgContainer, width: '1000px' }} breakpoint="lg">
              <div className={styles['logo-controller']}>
                <img onClick={() => { navigate('/') }} className={styles['link']} src={LogoImage} />
              </div>
              <div className={styles['content-sider']}>
                <MenuComponent
                  menuList={menuList}
                  openKey={openKey}
                  onChangeOpenKey={k => setOpenkey(k)}
                  selectedKey={selectedKey}
                  onChangeSelectedKey={k => setSelectedKey(k)}
                />
              </div>
            </Sider>
          </div>
      }
      <Layout className={styles['layout-controller']}>
        <HeaderComponent collapsed={collapsed} toggle={toggle} />
        <Content className={styles['layout-page-content']}>
          <Suspense fallback={null}>
            <div className={styles['route-area']}>
              <Outlet />
            </div>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  )
}

export default LayoutPage
