import request from '../utils/request'

function getTypes(params: any) {
  console.log('params: ', params)
  return request({
    url: '/types',
    method: 'get',
    params
  })
}

function getAreas() {
  return request({
    url: '/areas',
    method: 'get'
  })
}

function getSizes(params: any) {
  return request({
    url: '/sizes',
    method: 'get',
    params
  })
}

export {
  getTypes,
  getAreas,
  getSizes
}
