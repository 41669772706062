import './i18n'
import { ConfigProvider, Spin, theme as a } from 'antd'
import { FC, Suspense, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import localStorageService from './utils/localStorageService'
import RenderRouter from './routes'
import { history, HistoryRouter } from './routes/history'
import { setGlobalState } from './redux/global.store'
import { me } from './redux/user.store'
import variables from './styles/_vars.scss'

const App: FC = () => {
  const { locale } = useSelector((state: RootStateOrAny) => state.user)
  const { theme, loading } = useSelector((state: RootStateOrAny) => state.global)
  const dispatch = useDispatch()

  const setTheme = (dark = true) => {
    dispatch(
      setGlobalState({
        theme: dark ? 'light' : 'light',
      }),
    )
  }

  useEffect(() => {
    localStorageService.getItem('Token') && dispatch(me())
  }, [])

  useEffect(() => {
    setTheme(theme === 'dark')
  }, [])

  return (
    <ConfigProvider
      componentSize="middle"
      theme={{
        token: { colorPrimary: variables.primary, fontFamily: 'Noto Sans, sans-serif' },
        algorithm: theme === 'dark' ? a.darkAlgorithm : a.defaultAlgorithm,
      }}
    >
      <IntlProvider locale={locale.split('_')[0]}>
        <HistoryRouter history={history}>
          <Suspense fallback={null}>
            <Spin spinning={loading} className="app-loading-wrapper" tip="loading.."></Spin>
            <RenderRouter />
          </Suspense>
        </HistoryRouter>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default App
