import request from '../utils/request'

function getCustomer(params: any) {
  return request({
    url: '/customers',
    method: 'get',
    params
  })
}

function getCustomerById(id: any) {
  return request({
    url: `/customers/${id}`,
    method: 'get'
  })
}

function createCustomer(data: any) {
  return request({
    url: `/customers`,
    method: 'post',
    data: data

  })
}

function createPassword(data: any) {
  return request({
    url: `/users`,
    method: 'post',
    data: data
  })
}

function updateCustomer(data: any, id: number) {
  return request({
    url: `/customers/${id}`,
    method: 'put',
    data,
  })
}

function deleteCustomer(id: any) {
  return request({
    url: `/customers/${id}`,
    method: 'delete'
  })
}

export { getCustomer, getCustomerById, createCustomer, deleteCustomer, updateCustomer, createPassword }
