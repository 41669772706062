import request from '../utils/request'
import {handleImageUpload, removeBlankKeys} from '../utils/common'

async function imageUpload(images: any) {
  const formData = new FormData()
  for (const image of images) {
    const compressedImage:any = await handleImageUpload(image.originFileObj)
    formData.append('file', compressedImage)
  }

  return request({
    url: '/image',
    method: 'put',
    data: formData,
  })
}

function imageDelete(id: number) {
  return request({
    url: `/images/${id}`,
    method: 'delete',
  })
}

function createProperty(data: any) {
  return request({
    url: '/tiles',
    method: 'post',
    data,
  })
}

function getProperties(params: any = {}) {
  const result = removeBlankKeys(params)
  return request({
    url: '/tiles',
    method: 'get',
    params: result
  })
}

function getProperty(id: number) {
  return request({
    url: `/tiles/${id}`,
    method: 'get',
  })
}

function deleteProperty(id: any) {
  return request({
    url: `/tiles/${id}`,
    method: 'delete'
  })
}

function getOccupancies() {
  return request({
    url: '/properties/occupancy_statuses',
    method: 'get',
  })
}

function updateProperty(data: any, id: number) {
  return request({
    url: `/tiles/${id}`,
    method: 'put',
    data,
  })
}

function validatePostalCode(data: any) {
  return request({
    url: `/locations/validate_postal_code`,
    method: 'get',
    params: data,
  })
}

function uploadDocument(data: any) {
  const formData = new FormData()
  formData.append('name', data?.name)
  formData.append('file', data?.file)
  formData.append('property_id', data?.property_id)
  return request({
    url: `/documents`,
    method: 'post',
    data: formData,
  })
}

function updateDocument(data: any, id: number) {
  const formData = new FormData()
  formData.append('name', data?.name)
  data?.file && formData.append('file', data?.file)
  return request({
    url: `/documents/${id}`,
    method: 'put',
    data: formData,
  })
}

function publishProperty(data: any, id: number) {
  return request({
    url: `/tiles/publish/${id}`,
    method: 'put',
    data
  })
}

export {
  imageUpload,
  imageDelete,
  createProperty,
  getOccupancies,
  getProperties,
  getProperty,
  updateProperty,
  validatePostalCode,
  uploadDocument,
  updateDocument,
  publishProperty,
  deleteProperty
}
