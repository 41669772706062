import { Spin } from 'antd'

const Loading = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      alignItems: 'center'
    }}>
      <Spin size='large' />
    </div>
  )
}

export default Loading
