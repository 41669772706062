const localStorageService = {
  setItemWithExpiration: (key: any, value: any, expirationInHours = 24) => {
    const item = {
      value: value,
      expiry: new Date().getTime() + expirationInHours * 60 * 60 * 1000,
    }
    localStorage.setItem(key, JSON.stringify(item))
  },

  getItemWithExpiration: (key: any) => {
    const itemString = localStorage.getItem(key)
    if (itemString) {
      const item = JSON.parse(itemString)
      const currentTime = new Date().getTime()
      if (currentTime > item.expiry) {
        localStorage.removeItem(key)
        return null
      }
      return item.value
    }
    return null
  },

  setItem: (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  },

  getItem: (key: string) => {
    return JSON.parse(localStorage.getItem(key))
  },

  removeItem: (key: any) => {
    localStorage.removeItem(key)
    localStorage.clear()
  }
}

export default localStorageService
