import axios from 'axios'
import localStorageService from './localStorageService'

const baseURL = process.env.REACT_APP_API_BASE_URL

// Create Axios instance
const service = axios.create({
  baseURL,
  timeout: 100000
})

// Request interceptor
service.interceptors.request.use(
  config => {
    if (localStorage.getItem('Token')) {
      config!.headers!['Authorization'] = 'Bearer ' + localStorageService.getItem('Token')
    }
    config.headers!['Access-Control-Allow-Origin'] = '*'
    config.headers!['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    config.headers!['Accept-Language'] = 'en'
    return config
  },

  error => {
    console.log(error)
    Promise.reject(error)
  },
)

service.interceptors.response.use(
  response => response,
  async err => {
    if (err?.response?.data?.error === 'Your Session has been Expired') {
      localStorageService.removeItem('Token')
      window.location.reload()
    }
    return Promise.reject(err)
  },
)

export default service
