import type { MenuList } from '../../interface/layout/menu.interface'
import type { FC } from 'react'
import { Menu } from 'antd'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { setUserItem } from '../../redux/user.store'
// import { CustomIcon } from './customIcon'

interface MenuProps {
  menuList: MenuList
  openKey?: string
  onChangeOpenKey: (key?: string) => void
  selectedKey: string
  onChangeSelectedKey: (key: string) => void
}

const MenuComponent: FC<MenuProps> = props => {
  const { menuList, openKey, onChangeOpenKey, selectedKey, onChangeSelectedKey } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getTitle = (menu: MenuList[0]) => {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {/* <CustomIcon type={menu.icon!} /> */}
        <span>{menu.label}</span>
      </span>
    )
  }

  const onMenuClick = (path: string) => {
    onChangeSelectedKey(path)
    navigate(path)
    dispatch(setUserItem({ collapsed: true }))
  }

  const onOpenChange = (keys: string[]) => {
    const key = keys.pop()
    onChangeOpenKey(key)
  }

  return (
    <Menu
      mode="inline"
      selectedKeys={[selectedKey]}
      openKeys={openKey ? [openKey] : []}
      onOpenChange={onOpenChange}
      onSelect={k => {
        onMenuClick(k.key)
      }}
      className='menu-controller'
      style={{
        backgroundColor: 'transparent',
        color: 'white',
        padding: '16px 0px'
      }}
      items={menuList?.map(menu => {
        if (!menu.label) {
          return
        }
        return menu.children
          ? {
            key: menu.code,
            label: getTitle(menu),
            children: menu.children.map(child => ({
              key: child.path,
              label: child.label,
            })),
          }
          : {
            key: menu.path,
            label: getTitle(menu),
          }
      })}
    ></Menu>
  )
}

export default MenuComponent
