import React from 'react'

function CustomCloseIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="18.038" y1="18.2576" x2="6.03801" y2="6.25764" stroke="black" strokeWidth="2"/>
      <line x1="6.03801" y1="18.0439" x2="18.038" y2="6.04387" stroke="black" strokeWidth="2"/>
    </svg>
  )
}

export default CustomCloseIcon
